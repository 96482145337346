import React from 'react'
import {graphql} from 'gatsby'
import Img from 'gatsby-image'

import Layout from '../components/layout'
import SEO from '../components/seo'

const IndexPage = ({data}) => (
	<>
		<SEO
			pagetitle='稽古内容の紹介'
			pageDesc='相氣一進流の稽古内容を紹介するページです。。当流派では、合気道、居合道、柔術拳法、杖術、双桴術、手裏剣術の6つの術を稽古します。'
		/>
		<Layout>
			<div className='aboutPage'>
				<h1>稽古内容の紹介</h1>
				<div className='topPic'>
					<Img
						fluid={data.lessons.childImageSharp.fluid}
						className='lessonsPic'
					/>
				</div>
				<p className='subtitle'>
					当流派では、合気道、居合道、柔術拳法、杖術、双桴術、手裏剣術の6つの術を稽古します。
				</p>
				<div className='lessons'>
					<div className='lesson'>
						<h2>合気道</h2>
						<Img
							fluid={data.aikido.childImageSharp.fluid}
							className='lessonsPic'
						/>
						<p>
							合気道は、合気の術（相氣の術）を利用する柔術の一種です。
							当流派の合気道では、当身技、甲手技、腰技、寝固技など36本の基本技をベースに多様な技の稽古をしています。
							他の術すべてに共通する基本を学ぶことになるので、相氣一進流の初心者には特にお勧めです。
							年齢別に少年部、中学生の部、一般部やシニアの部など名称が分かれていますが、時間帯の目安に過ぎませんのでどなたでも好きな稽古に参加することが出来ます。
							また熟練度の異なる者同士での稽古も推奨していますので、年齢と同様に初心者、有段者関係なく好きな稽古に参加することも出来ます。
						</p>
					</div>
					<div className='lesson'>
						<h2>居合道</h2>
						<Img
							fluid={data.iaido.childImageSharp.fluid}
							className='lessonsPic'
						/>
						<p>
							居合道は、鞘から刀を抜く術である「抜刀術」を稽古する武道です。
							刀の扱い方や居合術を学ぶことで、より合気道の理解も深まります。
							初心者には、居合刀を無料で貸し出しています。
							居合刀の数には限りがあるので、事前に連絡してください。
						</p>
					</div>
					<div className='lesson'>
						<h2>柔術拳法</h2>
						<Img
							fluid={data.sogo.childImageSharp.fluid}
							className='lessonsPic'
						/>
						<p>
							本来、日本の武術には当身がありますが、パンチのような打撃はありません。
							そこで打撃を補うために作られたのが柔術拳法です。
							柔術拳法では、主に突き、蹴りの稽古をします。
							稽古は合気道総合の時間で行いますが、他流や他武道の経験者が集まった場合は総合格闘の勉強会をすることもあります。
							格闘技の経験者は勿論、初心者でも楽しめる稽古です。
						</p>
					</div>
					<div className='lesson'>
						<h2>杖術</h2>
						<Img
							fluid={data.jodo.childImageSharp.fluid}
							className='lessonsPic'
						/>
						<p>
							当流派の杖術では、刀を失った時に最悪鞘で身を守ることを想定し、三尺の杖を利用します。
							稽古では杖による打撃だけでなく投げ技の稽古もします。
							初心者は先ず合気道または居合道の基本を習得してから杖の練習を始めることになり、合気道や居合道の稽古中に行います。
						</p>
					</div>
					<div className='lesson'>
						<h2>双桴術</h2>
						<Img
							fluid={data.syugi.childImageSharp.fluid}
							className='lessonsPic'
						/>
						<p>
							双桴術(そうふじゅつ)は、手木術、短棒術、半棒術などの良いところを取り入れ、鈴木喜中が体系化した約一尺半の木の棒を使う武術です。
							3つの基本的な型と2つの奥義の型で構成されております。
						</p>
					</div>
					<div className='lesson'>
						<h2>手裏剣術</h2>
						<Img
							fluid={data.syuriken.childImageSharp.fluid}
							className='lessonsPic'
						/>
						<p>
							当流派の手裏剣術では、棒手裏剣を打ちます。
							基本的に有段者しか稽古していません。
						</p>
					</div>
				</div>
			</div>
		</Layout>
	</>
)

export const query = graphql`
	query {
		lessons: file(relativePath: {eq: "6lessons.png"}) {
			childImageSharp {
				fluid(maxWidth: 1600) {
					...GatsbyImageSharpFluid_withWebp
				}
			}
		}
		aikido: file(relativePath: {eq: "aikido.png"}) {
			childImageSharp {
				fluid(maxWidth: 1600) {
					...GatsbyImageSharpFluid_withWebp
				}
			}
		}
		iaido: file(relativePath: {eq: "iaido.jpg"}) {
			childImageSharp {
				fluid(maxWidth: 1600) {
					...GatsbyImageSharpFluid_withWebp
				}
			}
		}
		sogo: file(relativePath: {eq: "taiso.jpg"}) {
			childImageSharp {
				fluid(maxWidth: 1600) {
					...GatsbyImageSharpFluid_withWebp
				}
			}
		}
		jodo: file(relativePath: {eq: "jodo.jpg"}) {
			childImageSharp {
				fluid(maxWidth: 1600) {
					...GatsbyImageSharpFluid_withWebp
				}
			}
		}
		syugi: file(relativePath: {eq: "sofujutsu.jpg"}) {
			childImageSharp {
				fluid(maxWidth: 1600) {
					...GatsbyImageSharpFluid_withWebp
				}
			}
		}
		syuriken: file(relativePath: {eq: "syuriken.jpg"}) {
			childImageSharp {
				fluid(maxWidth: 1600) {
					...GatsbyImageSharpFluid_withWebp
				}
			}
		}
	}
`

export default IndexPage
